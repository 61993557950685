
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

$title-family: "Bebas Neue";
$title-size: 3.5rem;
$subtitle-family: "Bebas Neue";
$subtitle-size: 2rem;
$input-placeholder-color: rgba(#ffffff, 0.8);
$link: #3298dc;
$link-hover: #ffffff;
$link-focus: #ffffff;

$navbar-z: 5;
$navbar-dropdown-background-color: #363636;
$navbar-divider-height: 1px;

$modal-card-head-background-color: #363636;
$modal-card-title-color: #fff;
$modal-card-title-size: 3rem;
$modal-card-head-border-bottom: none;
$modal-card-body-background-color: #363636;
$modal-card-foot-border-top: none;

@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";

@import "./_animations.scss";
@import "./_loading.scss";
@import "./_result.scss";

h1, h2 {
	letter-spacing: 0.5px;
}

section.login {
	position: relative;

	.hero-image {
		position: absolute;
		bottom: -160%;
		left: -25%;
	}
}

.hero {
	background-image: url("../assets/png/default.png");
	background-size: cover;
	overflow: hidden;

	.hero-body {
		padding: 3rem 1.5rem 1.5rem;

		img {
			margin-bottom: 10px;
		}
	}
}

section.default {
	position: relative;

	.hero-image {
		position: absolute;
		bottom: -25%;
		right: -30%;
	}

	.hero-body {
		.button.is-info {
			margin-right: 15px;
		}
	}

	form + p {
		margin-top: 15px;

		a {
			color: #fff;
			text-decoration: underline;
		}
	}
}

section.terms {
	background: none;

	ul {
		list-style-type: disc;
		margin-left: 1.5rem;
		margin-bottom: 1rem;
	}
	
	p {
		margin-bottom: 1rem;
	}

	.title,
	.subtitle {
		text-align: center;
	}
}

section.contact {
	position: relative;

	.hero-body {
		z-index: 9999;
	}

	.hero-image {
		position: absolute;
		bottom: -25%;
		right: -30%;
	}

	.button.is-info {
		display: block;
		margin-right: 0;
		margin: 0 auto;
	}

	h2.subtitle {
		margin-bottom: 5px;
	}

	ul {
		list-style-type: disc;
		margin-left: 1.5rem;
		margin-bottom: 1rem;
	}
	
	p {
		margin-bottom: 1rem;

		i {
			margin-right: 10px;
		}
	}

	form {
		textarea {
			min-height: 41vh;
		}

		label {
			display: block;
			margin-bottom: 10px;
			text-align: center;
		}
	}

	.columns {
		.column {
			a {
				color: $link !important;
			}
			a, span {
				cursor: pointer;
				&:hover {
					color: $link;
				}
			}
		}
	}
}

.hero.is-fullheight.results {
	.hero-body {
		align-items: flex-start;
	}
}

.hero.is-fullheight.details {
	.hero-body {
		align-items: flex-start;
	}

	.columns {
		.column {
			.subtitle {
				margin-top: -0.75rem;
        margin-bottom: 0;
			}

			.thumbnail {
				background-size: cover;
        width: 500px;
        height: 500px;
        margin: 0 auto;
			}
		}
	}
}

.hero.is-fullheight.checkout {
	.hero-body {
		align-items: flex-start;

		.title {
			margin-bottom: 1rem;
		}

		.button.is-info {
			margin-bottom: 1rem;	
		}
	}
}

.checkbox, .radio {
	&:hover {
		color: #ffffff;
	}

	input[type="checkbox"] {
		margin-right: 5px;
	}
}

.table {
	background: #363636;
	color: #ffffff;
	display: block;
	max-height: 54vh;
	overflow-y: scroll;

	thead {
		td, th {
			color: #ffffff;
		}
	}

	th, td {
		border-color: rgba(#000000, 0.5);
		color: #ffffff;
		vertical-align: middle;

		&:nth-of-type(4),
		&:nth-of-type(5) {
			width: 120px;
		}
	}
}

.table.is-cart {
	background: #363636;
	color: #ffffff;
	display: block;
	max-height: 60vh;
	overflow-y: scroll;

	thead {
		td, th {
			color: #ffffff;
		}
	}

	th, td {
		border-color: rgba(#000000, 0.5);
		color: #ffffff;
		vertical-align: middle;

		&:nth-of-type(4),
		&:nth-of-type(5),
		&:nth-of-type(6),
		&:nth-of-type(7) {
			width: 120px;
		}
	}
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
	background: rgba(#000000, 0.5);
	color: #fff;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover,
.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
	background: rgba(#000000, 0.5);
	cursor: pointer;
}

.input {
	background: rgba(#000000, 0.5);
	color: #ffffff;
	border-color: rgba(#000000, 0.5);
}

.is-details {
	background: rgba(#000000, 0.5);
	color: #ffffff;

	strong {
		color: #ffffff;		
	}
}

.navbar-item {
	.button {
		font-size: 0.9rem;
	}
}

.navbar-item.has-dropdown {
	padding: 0.5rem 0.75rem;

	.button {
		font-size: 1.1rem;
	}
}

.hero.is-dark .navbar-dropdown .navbar-item {
	font-size: 1rem;
	color: #ffffff;
}

.navbar-dropdown {
	z-index: 999;
	max-width: 300px;

	.inventory {
		max-height: 200px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.controls {

		.navbar-item {
			height: auto;
			width: auto;

			.button {
				width: 100%;
			}
		}		
	}

	.navbar-item {
		display: block;
		width: 300px;
		height: 40px;
		white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;

	  &:focus,
	  &:focus-within {
	  	background-color: transparent !important;
	  }

	  span {
	  	display: block;
	  	width: 145px;
	  	overflow: hidden;
	  	text-overflow: ellipsis;
	  }

	  input {
	  	position: absolute;
	  	top: 4px;
	  	right: 50px;
	  	background: rgba(0, 0, 0, 0.5);
	  	width: 75px;
			height: 2em;
	  }

	  img {
	  	position: absolute;
	  	top: 13px;
	  	right: 25px;
	  	width: 15px;
	  	height: 15px;
	  	filter: invert(1);
	  }
	}
}

.modal-card-title {
	font-family: "Bebas Neue";
	text-align: center;
}

.modal-card-body {
	form {
		textarea {
			min-height: 20vh;
		}
	}
}

.modal-card-foot {
	justify-content: center;
	flex-direction: column;

	input[type="checkbox"] {
		margin-bottom: 15px;
	}
}

.footer {
	background: transparent;
	padding: 15px;

	ul {
		list-style-type: none;
		margin: 0 !important;
		padding: 0;

		li {
			display: inline-block;
			margin-right: 15px;
		}
	}
}

.button.is-info.is-active {
	background: darken($link, 25%);
}

.services-content {
	padding-top: 30px;
	min-height: 342px;

	h2.subtitle {
		margin-bottom: 5px;
	}

	p {
		margin-bottom: 15px;
	}

	ul li::before {
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		content: "\f00c";
		padding: 0 10px 0 0;
		font-size: 1.5rem;
	}

	ul, ol {
		margin-top: 10px;
		padding-left: 20px;
	}

	ul li h2.subtitle {
		display: inline-block;
		margin-bottom: 0;
	}
}

h2.subtitle.tagline span {
	color: $link;
}

h2.subtitle.tagline {
	font-size: 3rem;
}