div.loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  background: rgba(#363636, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  i {
    margin-bottom: 5px;
  }

  p {
    color: #ffffff;
  }
}