
// Result

.toast {
	position: fixed;
	top: 15px;
	right: 15px;
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;
  font-size: .875rem;
  background-color: rgba(255,255,255,1);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
  backdrop-filter: blur(10px);
  opacity: 1;
  border-radius: .25rem;
  z-index: 99999;
  animation: slideUp 0.4s ease;
  text-align: center;

  .toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);

    .mr-auto, .mx-auto {
    	margin-right: auto!important;
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			background: none;
			outline: none;
			cursor: pointer;
			padding-right: 0;

			i {
				font-size: 1.2em;
			}
		}
	}

	.toast-header.is-error {
		background-color: #feecf0;
		color: #cc0f35;

		strong {
			color: #cc0f35;
		}

		button {
			color: #cc0f35;

			i {
				color: #cc0f35;
			}
		}
	}

	.toast-header.is-success {
		background-color: #effaf3;
		color: #257942;

		strong {
			color: #257942;			
		}

		button {
			color: #257942;

			i {
				color: #257942;
			}
		}
	}

	.toast-body {
    padding: .75rem;
	}
}